// extracted by mini-css-extract-plugin
export var sbShowMain = "extensionHero-module--sb-show-main--2C1-7";
export var heroWithBackround = "extensionHero-module--heroWithBackround--2yh_X";
export var backgroundImage = "extensionHero-module--backgroundImage--2SfI6";
export var companyWrapper = "extensionHero-module--companyWrapper--3eLIw";
export var companyBackground = "extensionHero-module--companyBackground--2NFve";
export var header = "extensionHero-module--header--29gfy";
export var breadcrumbs = "extensionHero-module--breadcrumbs--29sX_";
export var companyImage = "extensionHero-module--companyImage--3hkCX";
export var body = "extensionHero-module--body--G0e0N";
export var storybook = "extensionHero-module--storybook--3EMnP";