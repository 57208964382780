/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import compact from "lodash/compact"
import { getGatsbyImageData } from "gatsby-source-sanity"

import { useConfig } from "../../graphql/useConfig"
import { config as clientConfig } from "../../helpers/clientConfig"

import Schema from "./schema"

const cleanCanonical = url => {
  try {
    const parsedUrl = new URL(url)
    let cleanedUrl = parsedUrl.origin + parsedUrl.pathname

    cleanedUrl = cleanedUrl.replace(/([^:]\/)\/+/g, "$1")
    return cleanedUrl
  } catch (e) {
    return url
  }
}

const createCanonicalUrl = (baseUrl, path) => {
  if (!baseUrl.endsWith("/")) {
    baseUrl += "/"
  }
  if (path.startsWith("/")) {
    path = path.substring(1)
  }
  return baseUrl + path
}

function SEO(props) {
  const {
    description,
    title,
    isBlogPost,
    breadcrumbs,
    canonical,
    author,
    image,
    openGraphImage,
    metaTitle,
    path,
    datePublished,
    dateModified,
    noIndex,
    video,
    faq,
  } = props
  const config = useConfig()

  const getImageUrl = () => {
    const providedImage = openGraphImage?.asset?.url || image
    if (providedImage && typeof providedImage === "string") return providedImage
    if (config?.ogImage?.asset?._ref) {
      const imageData = getGatsbyImageData(config.ogImage.asset._ref, {}, clientConfig)
      return imageData?.images?.fallback?.src || ""
    }
    return ""
  }

  const { lang, socialPlatforms } = config
  const defaultTitle = compact([title?.length ? title : metaTitle, config?.titleSection, config?.title]).join(" | ")
  const metaTitleSet = compact([metaTitle?.length ? metaTitle : title, config?.title]).join(" | ")
  const metaDescription = description?.length ? description : config?.description
  const metaImage = getImageUrl()
  const logoData = getGatsbyImageData(config?.logo?.asset?._ref, {}, clientConfig)
  const logoUrl = logoData?.images?.fallback?.src || ""

  const canonicalPath = canonical?.length
    ? cleanCanonical(canonical)
    : cleanCanonical(createCanonicalUrl(process.env.GATSBY_SITE_URL, path || ""))

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitleSet}
        titleTemplate={`%s`}
        link={
          canonicalPath
            ? [
                {
                  rel: "canonical",
                  href: canonicalPath,
                },
              ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitleSet,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: metaImage,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: metaTitleSet,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: metaImage,
          },
        ]}
      />
      {/* Hardcoded noindex for no indexed pages */}
      {noIndex && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}

      <Schema
        isBlogPost={isBlogPost}
        url={canonicalPath}
        title={metaTitleSet}
        image={metaImage}
        description={metaDescription}
        datePublished={datePublished}
        dateModified={dateModified}
        canonicalUrl={canonicalPath}
        author={author}
        organization={config}
        defaultTitle={defaultTitle}
        breadcrumbs={breadcrumbs}
        socialPlatforms={socialPlatforms}
        language={lang}
        video={video}
        logo={logoUrl}
        faq={faq}
      />
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  description: ``,
  title: "",
  isBlogPost: false,
  breadcrumbs: [],
  canonical: "",
  author: {},
  image: {},
  openGraphImage: {},
  metaTitle: "",
  path: "",
  datePublished: "",
  dateModified: "",
  noIndex: false,
  video: {},
  logo: "",
  faq: {},
}

SEO.propTypes = {
  lang: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  isBlogPost: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  canonical: PropTypes.string,
  author: PropTypes.object,
  image: PropTypes.object,
  openGraphImage: PropTypes.object,
  metaTitle: PropTypes.string,
  path: PropTypes.string,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  noIndex: PropTypes.bool,
  video: PropTypes.object,
  logo: PropTypes.string,
  faq: PropTypes.object,
}

export default SEO
