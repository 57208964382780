import React from "react"
import classNames from "classnames"

import Hero from "./hero"
import Image from "../image/image"
import Video from "../video/video"

import * as styles from "./hero.module.scss"

function HeroWithMedia({
  video,
  icons,
  hero,
  videoTitle,
  btnClassName,
  laptopContent,
  mainImage,
  className,
  withContainer,
  uploadedVideo,
}) {
  const renderContent = () => {
    if (mainImage) {
      if (withContainer)
        return (
          <div className={classNames("icon huge", styles.mainImage)}>
            <img src={mainImage.asset.url} alt="main" />
          </div>
        )
      return <img className={classNames(styles.mainImage, styles.simple)} src={mainImage.asset.url} alt="main" />
    }
    return (
      <Video
        className={styles.mainVideo}
        withLaptop
        video={video ?? uploadedVideo?.asset?.url}
        btnClassName={btnClassName}
        title={videoTitle}
        previewImage={laptopContent}
      />
    )
  }

  const renderIcons = () => {
    const [icon1, icon2, icon3, icon4] = icons.map((icon, idx) => (
      <Image key={icon._key} image={icon.asset} className={idx === 1 || idx === 2 ? "icon l" : "icon s"} />
    ))

    return (
      <div className={classNames(styles.icons, mainImage && styles.withImage, className)}>
        <div className="grid-line" />
        <div className={styles.iconContainer}>
          {icon1}
          {icon2}
        </div>
        {renderContent()}
        <div className={styles.iconContainer}>
          {icon3}
          {icon4}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.heroWithMedia}>
      <Hero {...hero} />
      {renderIcons()}
    </div>
  )
}

export default HeroWithMedia
