const getYoutubeData = async url => {
  if (!process.env.GATSBY_YOUTUBE_API_KEY) return null

  const videoId = url?.split("/").slice(-1).toString()
  const data = await fetch(
    `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${process.env.GATSBY_YOUTUBE_API_KEY}&part=snippet,contentDetails`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then(res => res?.json())
    .then(data => {
      const { title, description, thumbnails, publishedAt } = data?.items?.[0]?.snippet
      const { duration } = data?.items?.[0]?.contentDetails
      const thumbnailUrl = thumbnails?.default?.url

      return {
        title,
        description,
        duration,
        thumbnailUrl,
        publishedAt,
      }
    })
  return data
}

/**
 *
 * @param {string} url
 * @returns {{ name: string, description: string, uploadDate: Date, duration: string }}
 */

export const getAsyncVideoData = async url => {
  const isYoutube = url.includes("youtube-nocookie.com/") || url.includes("youtube.com/") || url.includes("youtu.be/")

  try {
    if (isYoutube) {
      return await getYoutubeData(url)
    }
  } catch (e) {
    console.error(e)
    return null
  }
}
