// extracted by mini-css-extract-plugin
export var sbShowMain = "hero-module--sb-show-main--2GjGO";
export var hero = "hero-module--hero--1Ngwp";
export var buttonContainer = "hero-module--buttonContainer--3b4Dr";
export var body = "hero-module--body--3F92V";
export var heroWithIcons = "hero-module--heroWithIcons--243GR";
export var icons = "hero-module--icons--2VPje";
export var iconContainer = "hero-module--iconContainer--YnXyf";
export var heroWithMedia = "hero-module--heroWithMedia--dDJug";
export var mainImage = "hero-module--mainImage--1BT4k";
export var simple = "hero-module--simple--3KpnT";
export var mainVideo = "hero-module--mainVideo--1Ud4M";
export var withImage = "hero-module--withImage--3QBvh";
export var additionalBackground = "hero-module--additionalBackground--pcU-P";
export var storybookPlayButton = "hero-module--storybookPlayButton--201YS";
export var storybook = "hero-module--storybook--2BVMk";