import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import RichText from "../richText/richText"
import Buttons from "../buttons/buttons"

import * as styles from "./hero.module.scss"

function Hero(props) {
  const { title, richText, links, titleStyle, background } = props
  const isGradient = titleStyle === "gradient"
  const isGradientBackground = background === "gradient"
  const hasBody = !!richText.length

  return (
    <div className={styles.hero}>
      <h1 className={classNames("text-align-center", isGradient && "gradient", hasBody && "mb-s")}>{title}</h1>
      {hasBody && (
        <div className={classNames(styles.body, "mb-s text-align-center", !isGradientBackground && "darkgrey")}>
          <RichText blocks={richText} />
        </div>
      )}
      <Buttons className={styles.buttonContainer} buttons={links} variant="outlined" />
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  richText: PropTypes.arrayOf(PropTypes.any),
  links: PropTypes.arrayOf(PropTypes.any),
  titleStyle: PropTypes.oneOf(["solid", "gradient"]),
}

Hero.defaultProps = {
  richText: [],
  links: [],
  titleStyle: "solid",
}

export default Hero
