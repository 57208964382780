import React from "react"
import classNames from "classnames"

import Hero from "./hero"
import Image from "../image/image"

import * as styles from "./hero.module.scss"

function HeroWithIcons(props) {
  const { icons, hero, className } = props

  const renderIcon = (icon, idx) => {
    if (icon.renderIcon) return icon.renderIcon({ className: idx === 3 ? "icon xxl" : "icon m", index: idx })
    return <Image key={icon.asset._id} image={icon.asset} className={idx === 3 ? "icon xxl" : "icon m"} />
  }

  const renderIcons = () => {
    return (
      <div className={classNames(styles.icons, "mt-xs", className)}>
        <div className="grid-line" />
        <div className={styles.iconContainer}>{icons?.map(renderIcon)}</div>
      </div>
    )
  }

  return (
    <div className={styles.heroWithIcons}>
      <Hero {...hero} />
      {renderIcons()}
    </div>
  )
}

export default HeroWithIcons
