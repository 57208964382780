import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import startCase from "lodash/startCase"
import classNames from "classnames"

import Section from "../section/section"
import { markets } from "../../consts/markets"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./breadcrumbs.module.scss"

const getPath = (pages, slug, idx) => {
  if (!idx) return `/${slug}`
  return `/${pages.slice(0, idx).join("/")}/${slug}`
}

const Breadcrumbs = ({ steps, className, homePageLabel, fontColor }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const hasProvidedSteps = !!steps?.length

  const renderProvidedSteps = () =>
    steps.map(({ label, path }, idx) => {
      const isFirst = !idx
      const isLink = steps.length !== 1 && path !== "*" && path
      const translatedLabel = t(label);

      return (
        <span key={path || label}>
          {!isFirst && <span> / </span>}
          {isLink ? <Link to={path}>{translatedLabel}</Link> : <span key={path}>{translatedLabel}</span>}
        </span>
      )
    })

  /** breadcrumbs generate automatically if there's not steps provided */
  const renderGeneratedSteps = () => {
    const pages = location.pathname.split("/").filter(slug => slug)

    return pages.map((slug, idx) => {
      const hasMarketSlug = Object.values(markets).includes(pages[0])
      if (!idx && hasMarketSlug) return null
      const isFirst = hasMarketSlug ? idx === 1 : !idx
      const label = t(isFirst && homePageLabel ? homePageLabel : startCase(slug))
      const isCurrentSlug = idx + 1 === pages.length

      return (
        <span key={slug || label}>
          {isFirst || <span> / </span>}
          {isCurrentSlug ? <strong>{label}</strong> : <Link to={getPath(pages, slug, idx)}>{label}</Link>}
        </span>
      )
    })
  }

  const stepLists = hasProvidedSteps ? renderProvidedSteps() : renderGeneratedSteps()

  return <Section className={classNames(styles.breadcrumbs, "h4", fontColor, className)}>{stepLists}</Section>
}

Breadcrumbs.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  homePageLabel: PropTypes.string,
  fontColor: PropTypes.oneOf(["", "gradient", "primary", "secondary"]),
}

Breadcrumbs.defaultProps = {
  className: "",
  steps: [],
  homePageLabel: "",
  fontColor: "gradient",
}

export default Breadcrumbs
