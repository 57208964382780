import React from "react"

import Section from "../section/section"
import Hero from "./hero"
import HeroWithMedia from "./heroWithMedia"
import ExtensionHero from "./extensionHero/extensionHero"
import HeroWithIcons from "./heroWithIcons"
import HomeHero from "./homeHero/homeHero"

const getComponents = content => {
  switch (content?._type) {
    case "hero":
      return <Hero {...content} />
    case "heroWithVideo":
    case "heroWithImage":
      return <HeroWithMedia {...content} />
    case "heroWithIcons":
      return <HeroWithIcons {...content} />
    case "extensionHero":
      return <ExtensionHero {...content} />
    case "homeHero":
      return <HomeHero {...content} />
    default:
      return null
  }
}

const getBackground = content => {
  if (["heroWithIcons", "heroWithImage", "heroWithVideo"].includes(content?._type))
    return content.hero?.background || content.background
  return content.background
}

const HeroSelector = ({ heroSelector }) => {
  return (
    <>
      {heroSelector.map(content => {
        const isHomeHero = content?._type === "homeHero"
        const contentBody = <>{getComponents(content)}</>

        return isHomeHero ? (
          <React.Fragment key={content._key || content.title}>{contentBody}</React.Fragment>
        ) : (
          <Section key={content._key || content.title} background={getBackground(content)}>
            {contentBody}
          </Section>
        )
      })}
    </>
  )
}

export default HeroSelector
