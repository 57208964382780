import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { getAsyncVideoData } from "../../helpers/getAsyncVideoData"

const Schema = ({
  author,
  canonicalUrl,
  datePublished,
  dateModified,
  defaultTitle,
  description,
  image,
  isBlogPost,
  organization,
  title,
  url,
  socialPlatforms,
  language,
  breadcrumbs,
  video,
  logo,
  faq,
}) => {
  const [videoData, setVideoData] = useState({})

  const questions = faq?.steps?.map(item => {
    return {
      "@type": "Question",
      name: item.title,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.text,
      },
    }
  })

  useEffect(() => {
    if (video?.url) {
      getAsyncVideoData(video?.url).then(res => setVideoData(res))
    }
  }, [video])

  const breadcrumbsSteps =
    breadcrumbs?.length &&
    breadcrumbs.map((item, index) => {
      return {
        type: "ListItem",
        position: index + 1,
        name: item.title,
        item: `${process.env.GATSBY_SITE_URL}/${item.link}`,
      }
    })

  const breadcrumbsSchema = breadcrumbs.length
    ? {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [...breadcrumbsSteps],
      }
    : null

  const videoSchema = video.url && {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: videoData?.title,
    description: videoData?.description,
    thumbnailUrl: videoData?.thumbnailUrl,
    uploadDate: videoData?.publishedAt,
    duration: videoData?.duration,
    publisher: {
      "@type": "Organization",
      name: organization.title,
      logo: {
        "@type": "ImageObject",
        url: logo,
        width: 660,
        height: 161,
      },
    },
    contentUrl: video.url,
  }

  const faqSchema = faq?.steps && {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: questions,
  }

  const baseSchema = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: organization.url,
      name: organization.title,
      alternateName: defaultTitle,
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      url: canonicalUrl,
      name: title,
      alternateName: defaultTitle,
      datePublished,
      dateModified,
      isPartOf: organization.url,
      inLanguage: language,
      primaryImageOfPage: image,
    },
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: organization.url,
      name: organization.title,
      logo: logo,
      sameAs: socialPlatforms,
    },
  ]

  const wholeBaseSchema = [...baseSchema, breadcrumbsSchema, videoSchema, faqSchema].filter(Boolean)

  const blogSchema = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    url,
    name: title,
    alternateName: defaultTitle,
    headline: title,
    image: {
      "@type": "ImageObject",
      url: image,
    },
    description,
    author: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: organization.title,
      url: organization?.url,
      logo: {
        "@type": "ImageObject",
        url: logo,
      },
    },
    mainEntityOfPage: {
      "@type": "WebSite",
      "@id": canonicalUrl,
    },
    datePublished,
    dateModified,
    inLanguage: language,
  }

  const schema = isBlogPost ? [...wholeBaseSchema, blogSchema] : wholeBaseSchema

  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default Schema
