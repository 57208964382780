import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import RichText from "../../richText/richText"
import Buttons from "../../buttons/buttons"
import Breadcrumbs from "../../breadcrumbs/breadcrumbs"

import * as styles from "./extensionHero.module.scss"

function ExtensionHero(props) {
  const {
    title,
    subtitle,
    richText,
    background_image,
    company_image,
    buttons,
    className,
    background_title,
    company_name,
  } = props

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <Breadcrumbs className={styles.breadcrumbs} homePageLabel="extensions" fontColor="" />
        <h1 className="text-align-center">{title}</h1>
        <div className="body2">{subtitle}</div>
      </div>
    )
  }

  return (
    <div className={classNames(styles.heroWithBackround, className)}>
      <div className={styles.backgroundImage}>
        <img src={background_image?.asset?.url} alt={background_title} />
      </div>
      <div className="grid-line" />
      <div className={styles.companyWrapper}>
        {renderHeader()}
        <div className={styles.companyBackground} />
        <div className={classNames("container", styles.companyImage)}>
          <img src={company_image?.asset?.url} alt={company_name} />
        </div>
      </div>
      {!!richText?.length && (
        <div className={classNames(styles.body, "body2")}>
          <RichText blocks={richText} />
        </div>
      )}
      <Buttons xl className="mt-s" buttons={buttons} />
    </div>
  )
}

ExtensionHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  richText: PropTypes.arrayOf(PropTypes.any),
  background_image: PropTypes.objectOf(PropTypes.any).isRequired,
  company_image: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string,
  background_title: PropTypes.string,
  company_name: PropTypes.string.isRequired,
}

ExtensionHero.defaultProps = {
  richText: [],
  subtitle: "",
  className: "",
  background_title: "",
}

export default ExtensionHero
